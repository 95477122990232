<template>
  <div>
    <v-card flat>
      <v-card-title class="align-end">
        <v-text-field
          style="max-width: 400px"
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          clearable
          dense
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          @click="dialogSyncSales = !dialogSyncSales"
          align="center"
          color="primary"
          class="white--text mr-2"
        >
          <v-icon left> mdi-file-excel-outline</v-icon>
          Sincronizar Vendas
        </v-btn>
        <v-btn
          color="save"
          @click="cadastrarUsuarios"
          class="white--text ml-4"
          depressed
          :disabled="adicionandoUsers"
        >
          <v-icon left>mdi-account-plus-outline</v-icon>
          Cadastrar Usuários
        </v-btn>
        <v-overlay :value="overlay">
          <v-row>
            <v-col cols="12" md="12" v-if="importando_vendas">
              <div class="d-flex justify-center">
                <v-progress-circular
                  indeterminate
                  size="64"
                ></v-progress-circular>
              </div>
              <br />
              <div v-if="importando_vendas" class="d-flex">
                Importando Vendas . . .
              </div>
            </v-col>
            <v-col
              cols="12"
              md="12"
              v-else
              class="d-flex"
              style="width: 800px !important"
            >
              <v-progress-circular
                indeterminate
                class="mr-4"
                size="36"
              ></v-progress-circular>
              <v-progress-linear
                :value="valueDeterminate"
                :background-opacity="0.5"
                color="xbColor"
                height="40px"
                rounded
                class=""
              >
                <template v-slot="{}">
                  <strong>
                    {{ sales_nao_cadastradas.length }} usuarios restantes
                  </strong>
                </template>
              </v-progress-linear>
            </v-col>
          </v-row>
        </v-overlay>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :search="search"
          :items="sales"
          :loading="loadingVendas"
          :items-per-page="10"
          class="data-tables data-tables__row-click"
        >
          <template v-slot:item.date_payment="{ item }">
            {{ item.date_payment | dateFormat("dd/MM/yyyy") }}
          </template>
          <template v-slot:item.status="{ item }">
            {{ item.status === 0 ? "Pendente" : "Cadastrado" }}
          </template>
          <template v-slot:item.client_cel="{ item }">
            <div class="d-flex align-center justify-start">
              <span>{{ item.client_cel }}</span>

              <v-btn x-small icon class="ml-1" v-if="item.client_cel">
                <v-icon
                  small
                  @click.stop="goToWhats(item.client_cel)"
                  color="green"
                >
                  mdi-whatsapp
                </v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  <DialogSyncSales :dialogSyncSales.sync="dialogSyncSales" />
  </div>
</template>

<script>
import { all as getSales } from "@/api/admin/eduzz.js";
import { postUsuarioSales } from "@/api/admin/usuarios.js";

export default {
  name: "Sales",
  components: {
    DialogSyncSales: () => import("./DialogSyncSales.vue"),
  },
  data() {
    return {
      sales: [],
      loading: false,
      search: "",
      vendas: [],
      loadingVendas: false,
      loadinSales: false,
      overlay: false,
      valueDeterminate: 0,
      adicionandoUsers: false,
      pararGeracao: false,
      sales_nao_cadastradas: [],
      importando_vendas: false,
      dialogSyncSales: false,
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "sale_id",
          value: "sale_id",
        },

        {
          text: "Pagamento",
          value: "date_payment",
        },
        {
          text: "Total",
          value: "sale_total",
        },
        {
          text: "Nome",
          value: "client_name",
        },
        {
          text: "Email",
          value: "client_email",
        },
        {
          text: "Documento",
          value: "client_document",
          sortable: false,
        },
        {
          text: "Telefone",
          value: "client_cel",
          sortable: false,
        },
        {
          text: "Lote",
          value: "content_title",
        },
        {
          text: "VIP",
          value: "vip",
        },
        {
          text: "status",
          value: "status",
        },
      ];
    },
  },

  methods: {
    goToWhats(telefone) {
      let url = "https://api.whatsapp.com/send?phone=";

      let telefone_sanatizado = telefone.replace(/\D/g, "");

      if (telefone_sanatizado.substr(0, 3) == "595") {
        url = url + telefone_sanatizado;
        window.open(url, "_blank");
      } else if (telefone_sanatizado.substr(0, 2) == "55") {
        url = url + telefone_sanatizado;
        window.open(url, "_blank");
      } else {
        url = url + "55" + telefone_sanatizado;
        window.open(url, "_blank");
      }
    },
    async fetchSales() {
      this.loadingSales = true;
      await getSales()
        .then((response) => {
          this.sales = response;
          this.sales_nao_cadastradas = this.sales.filter((item) => {
            return item.status === 0;
          });
          this.loadingSales = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingSales = false;
        });
    },

    // async fetchVendas() {
    //   this.loadingVendas = true;

    //   this.overlay = true;
    //   this.importando_vendas = true;

    //   await getVendas()
    //     .then((response) => {
    //       this.vendas = response;
    //       this.fetchSales();
    //       this.loadingVendas = false;
    //       this.overlay = false;
    //       this.importando_vendas = false;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       this.loadingVendas = false;
    //     });
    // },

    async cadastrarUsuarios() {
      try {
        let total = this.sales_nao_cadastradas.length - 1;

        if (this.sales_nao_cadastradas.length) {
          this.adicionandoUsers = true;
          this.overlay = true;

          for (let i = 0; i <= total; i++) {
            if (!this.pararGeracao) {
              // this.sales_nao_cadastradas[0].gravando = true;
              this.$Progress.start();
              const response = await postUsuarioSales(
                this.sales_nao_cadastradas[0]
              );
              if (response.status === 201) {
                this.valueDeterminate = (i / total) * 100;
                this.$Progress.finish();
                this.sales_nao_cadastradas.splice(0, 1);
              } else if (response.status === 200) {
                this.valueDeterminate = (i / total) * 100;
                this.$Progress.finish();
                this.sales_nao_cadastradas.splice(0, 1);
              }
            } else {
              i = total;
            }
          }
          if (this.pararGeracao) {
            this.$toast.success("Importação de usuários pausado");
            this.valueDeterminate = 0;
            this.overlay = false;
          } else {
            this.$toast.success("Importação de usuários finalizado");
            this.overlay = false;
            this.fetchSales();
          }
          this.adicionandoUsers = false;
          this.pararGeracao = false;
        } else {
          this.$toast.error("Todos usuários já foram importados!");
        }
      } catch (error) {
        console.log(error);
        if (error.status === 406 || error.status === 422) {
          this.$Progress.finish();
          this.pararGeracao = true;
          this.valueDeterminate = 0;
          this.adicionandoUsers = false;
          this.fetchSales();
        }
      }
    },
  },

  async mounted() {
    this.loading = true;
    await this.fetchSales();

    this.loading = false;
  },
};
</script>

<style></style>
